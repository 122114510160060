<template>
  <div class="flex align-center justify-center my-4"></div>
</template>

<script>
//import { mapState, mapGetters  } from 'vuex';

export default {
  name: "Painel",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {
    // ...mapState({
    //   item: state => state.namespace.item
    // }),
    // ...mapGetters({
    //   item: "namespace/item"
    // })
  },
  watch: {},
  async created() {},
  async mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss"></style>
